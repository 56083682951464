import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import normalizeTag from "../utils/normalize_tag"

const Tags = ({ data }) => (
  <ul className="pl-0">
    {Object.entries(data.allMdx.edges.reduce((acc, val) => {
      val.node.frontmatter.tags.forEach((v) => {
        acc[v] = (acc[v] || 0) + 1
      })
      return acc
    }, {})).map(([tag, num]) => <li key={tag} className="mb-2"><Link to={"/" + normalizeTag(tag)}>{tag} ({num})</Link></li> )}
  </ul>
)

export default function TagList(props) {
  return (
    <StaticQuery
      query={graphql`
        query {
          allMdx {
            edges {
              node {
                id
                frontmatter {
                  tags
                  slug
                  title
                }
              }
            }
          }
        }
      `}
      render={data => <Tags data={data} {...props} />}
    />
  )
}
