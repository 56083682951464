
export const SITE_TITLE = "internot Tech Blog"

export const THEME = {
  grey50:  "#F9F9FA",
  grey100: "#E0E6E9",
  grey200: "#AFBEC7",
  grey300: "#94A4AE",
  grey400: "#556874",
  grey500: "#3A474F",
  grey600: "#303A40",
  grey700: "#252E33",
  grey800: "#1C2226",

  primary50:  "#F0F4FF",
  primary100: "#CFDCFC",
  primary200: "#98B1F0",
  primary300: "#7697EB",
  primary400: "#547DE6",
  primary500: "#3263E1",
  primary600: "#1D4DC8",
  primary700: "#173EA0",
  primary800: "#122E78",

  secondary50:  "#FFFFB7",
  secondary100: "#FFF8A5",
  secondary200: "#FFF599",
  secondary300: "#FFF185",
  secondary400: "#FFEE70",
  secondary500: "#FFEC5C",
  secondary600: "#FFE747",
  secondary700: "#FFE433",
  secondary800: "#FFDD1F",
}
