import React from "react"
import TagList from "./tag-list"
import styled from "styled-components"

const TagHeading = styled.div`
  color: ${props => props.theme.grey700};
  background-color: ${props => props.theme.grey50};
  border-bottom: solid 5px ${props => props.theme.primary100};
  text-align: center;
  font-size: 1.4rem;
  line-height: 2;
  margin-bottom: 1rem;
`

export default function Layout({ children }) {
  return (<>
    <header>
      <div className="w-100 text-center mt-3">
        <img src="/internot-logo.png" alt="internot-logo" />
      </div>
    </header>
    <div className="container mt-5">
      <div className="row">
        <div className="col-9">
          {children}
        </div>
        <div className="col-3">
          <TagHeading>Tags</TagHeading>
          <TagList/>
        </div>
      </div>
    </div>
  </>)
}
